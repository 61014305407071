<template>
  <div>
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template v-if="hasModuleAccess">
        <Card class="px-3 pt-5 flex flex-col gap-1" v-if="reviewItems.length">
          <div class="flex justify-between items-center">
            <div class="flex justify-start">
              <div v-if="reviewItems.length >= 3">
                <div class="w-full px-5 py-1 flex gap-2 rounded-md bg-red-100 border border-flame">
                  <Icon icon-name="icon-info" size="xs" class="text-flame" />
                  <span class="text-flame w-full rounded-md text-center">
                    You can only have 3 reviews at a time
                  </span>
                </div>
              </div>
            </div>
            <Button
              label="Add New"
              class="bg-dynamicBackBtn text-white button_class"
              :disabled="reviewItems.length >= 3"
              @onClick="onReviewActions({}, 'create')"
            />
          </div>
          <template>
            <Table
              :headers="headers"
              :items="reviewItems"
              aria-label="compensation review table"
              class="w-full pt-4 mb-4"
              :has-number="true"
              :loading="isFetching"
              :key="JSON.stringify(reviewItems)"
            >
              <template v-slot:item="{ item }">
                <div v-if="item.reviewCycle">
                  <span class="font-normal text-cs leading-5 text-darkPurple">
                    {{ item.data.reviewCycle }}
                  </span>
                </div>
                <div v-if="item.startDate">
                  <span class="font-normal text-sm leading-5 text-darkPurple whitespace-nowrap">
                    {{ $DATEFORMAT(new Date(item.data.startDate), "MMMM dd, yyyy") }}
                  </span>
                </div>
                <div v-if="item.endDate">
                  <span class="font-normal text-sm leading-5 text-darkPurple whitespace-nowrap">
                    {{ $DATEFORMAT(new Date(item.data.endDate), "MMMM dd, yyyy") }}
                  </span>
                </div>
                <div v-if="item.id" class="flex justify-center items-center gap-10">
                  <div @click="onReviewActions(item.data, 'update')">
                    <span class="text-blueCrayola cursor-pointer">
                      <Icon icon-name="edit" size="xs" />
                    </span>
                  </div>
                  <div @click="onReviewActions(item.data, 'delete')">
                    <span class="text-flame cursor-pointer">
                      <Icon icon-name="delete" size="xs" />
                    </span>
                  </div>
                </div>
              </template>
            </Table>
          </template>
        </Card>
        <template v-else>
          <div class="flex flex-col justify-center items-center gap-5 px-10 py-20">
            <Icon icon-name="review_Illustration" size="" class-name="w-56 h-56" />
            <p class="font-normal text-base leading-5 text-jet text-center">
              An Overview of Reviews can be found here. This is empty now, but you can start adding reviews to populate this area.
            </p>
            <div @click="onReviewActions({}, 'create')" class="">
              <div class="hover:bg-ghostWhite px-5 py-2 rounded-md cursor-pointer">
                <div class="flex justify-start items-center gap-2">
                  <Icon icon-name="icon-plus" size="xs" />
                  <span class="font-semibold text-base text-flame leading-5">
                    Add New
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <Modal v-if="isDelete">
          <Card class="p-5 flex flex-col max-w-md">
            <div class>
              <p class="font-bold ml-4 my-5 text-center">
                Are you sure you want to delete this review?
              </p>
              <div class="flex justify-end items-center gap-2">
                <Button
                  class="button-class-pimary btn-bg"
                  label="Delete"
                  @onClick="onDelete(payload.id)"
                />
                <Button
                  label="Cancel"
                  class="button-class-secondary btn-border"
                  @onClick="isDelete = false"
                />
              </div>
            </div>
          </Card>
        </Modal>
        <CompensationReview ref="new_review" @success="getOrgReviewCycles" />
      </template>
      <ErrorComponent v-else />
    </template>
  </div>
</template>

<script>
  import Loader from "@scelloo/cloudenly-ui/src/components/loader";
  import Table from "@scelloo/cloudenly-ui/src/components/table";
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import Button from "@scelloo/cloudenly-ui/src/components/button";
  import Icon from "@/components/Icon";
  import Modal from "@/components/Modal";
  import ErrorComponent from "@/modules/Admin/error403"

  export default {
    components: {
      Card,
      Modal,
      Loader,
      Table,
      Button,
      Icon,
      ErrorComponent,
      CompensationReview: () => import("./Modals/AddNewReview"),
    },
    data() {
      return {
        isLoading: true,
        isFetching: true,
        isDelete: false,
        hasModuleAccess: false,
        reviewItems: [],
        payload: {},
        headers: [
          { title: "review cycle", value: "reviewCycle" },
          { title: "Start Date", value: "startDate" },
          { title: "End Date", value: "endDate" },
          { title: "", value: "id" },
        ],
      };
    },
    methods: {
      async onReviewActions(payload, setAction){
        try {
          if(setAction === 'create'){
            await this.$handlePrivilege('compensation', 'addNewReview')
            this.$refs.new_review.toggle(payload)
          } else if(setAction === 'update'){
            await this.$handlePrivilege('compensation', 'editReview')
            this.$refs.new_review.toggle(payload)
          } else if (setAction === 'delete'){
            await this.$handlePrivilege('compensation', 'deleteReview')
            this.isDelete = true
            this.payload = payload
          }

        } catch(error) {
          const message = 'You do not have permission to perform this task'
          this.$toasted.error(message, { duration: 3000 })
        }
      },
      onDelete(reviewId){
        this.$_deleteOrgReviewCycles(reviewId).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.getOrgReviewCycles()
          this.isDelete = false
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.getOrgReviewCycles()
          this.isDelete = false
        })
      },
      async getOrgReviewCycles(){
         this.isFetching = true
        await this.$_getOrgReviewCycles().then(({ data }) => {
          this.reviewItems = data.data
          this.isFetching = false
        })
      },
    },
    async created(){
      this.isLoading = true

      try {
        await this.$handlePrivilege('compensation', 'accessReviewSettings')
        this.hasModuleAccess = true
        await this.getOrgReviewCycles()
      } catch (error) {
        this.hasModuleAccess = false
      }

      this.isLoading = false
    },
  };
</script>

<style scoped>
  .button_class{
    padding: 10px 40px !important;
    font-weight: 600;
    display: flex;
    justify-self: end;
  }
  .button-class-pimary {
    font-weight: 600 !important;
    font-size: 16px !important;
    padding: 10px 40px !important;
    background-color: var(--dynamic-Background) !important;
  }
  .button-class-secondary {
    color: #321C3B !important;
    background: white !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    padding: 10px 40px !important;
  }

  .btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
